/* src/components/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the modal content is above the backdrop */
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
  z-index: 1001; /* Ensure the modal content is above the backdrop */
}

.modal-close {
  position: absolute;
  top: 18px;
  right: 18px;
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
}
.modal-close:hover {
  color: brown;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); /* Apply blur effect */
  z-index: 999; /* Ensure the backdrop is below the modal content but above other content */
}
