@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

body {
  /* Applying a radial gradient background */
  background: radial-gradient(circle, #aecf8c, #83ab84, #5f8878, #406866);
  height: 100vh;
  margin: 0;
}

.TranscptionPgaes {
  overflow: hidden;
}

/* input,
button {
  margin: 10px;
  padding: 10px;
} */

h2 {
  margin-top: 20px;
}
.audio-card {
  background-color: #d4d4d4; /* Adjust this color if necessary */
  padding: 16px;
  border-radius: 8px;
  width: 450px;
  font-family: Arial, sans-serif;
}

.audio-waveform {
  width: 100%;
  height: 60px;
  background-color: #b0b0b0; /* Adjust this color if necessary */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.audio-player {
  width: 100%;
}

.audio-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.audio-details p {
  margin: 0;
}

.view-more {
  background-color: #ffcc00;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  margin-top: 8px;
}

.view-more:hover {
  background-color: #e6b800;
}

/*  */
.pw-20 {
  width: 20%;
}
.pw-40 {
  width: 40%;
}

/* .pw-100 {
  width: 100%;
} */

/*  */
.transcription-details {
  font-family: Arial, sans-serif;
  margin: 15px;
  padding: 10px;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 85vh;
}

.info-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-item {
  background-color: #fffcfc;
  padding: 10px;
  border-radius: 4px;
  flex: 1;
  text-align: center;
  margin: 0 10px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  padding: 10px;
}

.tabs button {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  flex: 1;
  text-align: center;
}

.tabs button.active {
  background-color: #ffd700;
}
.headbg {
  background: linear-gradient(180deg, #203b32 0%, #1d3a2f 48.5%, #203b32 97%);
}
.headcolor {
  color: transparent !important;
}
.transcript {
  margin-bottom: 20px;
  border: 1px solid white;
  padding: 15px;
  border-radius: 10px;
}

.transcript h3 {
  margin-bottom: 10px;
}

.transcript p {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.speaker-confidences {
  margin-top: 20px;
}

.speaker-confidences h4 {
  margin-bottom: 10px;
}

.speaker-confidences ul {
  list-style: none;
  padding: 0;
}

.speaker-confidences li {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.transcript-content {
  max-height: 505px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
}

.sentiment-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.sentiment-icon {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin: 0 10px;
}

.sentiment-icon.active {
  opacity: 1;
  transform: translateY(0);
}

.sentiment-icon svg {
  width: 50px;
  height: 50px;
  fill: #2ecc71; /* Replace with your desired color */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom and Attractive CSS for Select Element */
.form-group {
  margin-bottom: 1rem;
}

select {
  display: block;
  /* width: 100%; */
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f8f9fa;
  background-image: linear-gradient(45deg, transparent 50%, #3490dc 50%),
    linear-gradient(135deg, #3490dc 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #e2e6ea;
}

select option {
  padding: 0.5rem;
}

select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  select {
    transition: none;
  }
}

.success-alert {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  animation: slideIn 0.5s forwards, fadeOut 2s 2.5s forwards;
  z-index: 999;
}

@keyframes slideIn {
  from {
    transform: translateX(-50%) translateY(-100%);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Add more custom styles as needed */
/* Tickets.css */
.case-enter {
  opacity: 0;
  transform: scale(0.9);
}
.case-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.case-exit {
  opacity: 1;
  transform: scale(1);
}
.case-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Tickets.css */

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.table-header,
.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.table-header .table-cell {
  background-color: #f4f4f4;
  font-weight: bold;
}

.case-row-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.case-row-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.case-row-exit {
  opacity: 1;
  transform: translateY(0);
}

.case-row-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}

.view-button {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #0056b3;
}

.status-open {
  color: green;
  font-weight: bold;
}

.status-closed {
  color: red;
  font-weight: bold;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
}

form {
  margin-top: 1rem;
}

form .form-group {
  margin-bottom: 1rem;
}

.audio-card {
  max-width: 600px;
  margin: 0 auto;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.audio-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-input {
  padding: 6px;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-size: 16px;
}

.form-input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
  outline: none;
}

.animated-input {
  position: relative;
  background-color: #f9f9f9;
}

.animated-input::placeholder {
  color: transparent;
}

.animated-input:focus::placeholder {
  color: #aaa;
}

.animated-input::before {
  content: attr(name);
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: #aaa;
  background-color: white;
  padding: 0 4px;
  transition: all 0.3s ease;
  pointer-events: none;
}

.animated-input:focus::before,
.animated-input:not(:placeholder-shown)::before {
  top: -12px;
  left: 8px;
  font-size: 10px;
  color: #4a90e2;
}

@media (max-width: 600px) {
  .form-container {
    padding: 15px;
  }

  .form-input {
    font-size: 14px;
  }
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
}

.animate-pulse {
  animation: pulse 1s infinite;
}

.ner {
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  animation: fadeIn 1s ease-out;
}

.ner:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 0.5;
    transform: translateY(0);
  }
}

.ner-list {
  list-style-type: none;
  padding: 0;
}

.ner-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  border-left: 6px solid transparent;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.ner-item:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.ner-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transform: skewX(-30deg);
  z-index: 0;
}

.ner-text {
  font-weight: bold;
  color: #000;
  position: relative;
  z-index: 1;
}

.ner-type {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
  background: #eee;
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

.ner-item.ner-person {
  border-left-color: #4caf50;
}

.ner-item.ner-location {
  border-left-color: #2196f3;
}

.ner-item.ner-organization {
  border-left-color: #ff9800;
}

.ner-item.ner-date {
  border-left-color: #9c27b0;
}

.ner-item.ner-other {
  border-left-color: #607d8b;
}
.transcriptionh {
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.ner h3 {
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
/* action points */
/* Container for the action points section */
.actions-section {
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 20px 0; /* Space above and below the section */
  border: 1px solid #fff;
  border-radius: 10px;
}

.actions-section h3 {
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Dropdown for language selection */
.actions-section select {
  padding: 8px 12px; /* Padding for better touch targets */
  border: 1px solid #ddd; /* Light border for dropdown */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background for dropdown */
  font-size: 0.875rem; /* Slightly smaller font size */
}

/* List container */
.action-points-content {
  margin-top: 20px; /* Space above the list */
}

/* Style for list items */
.action-points-content ul {
  padding-left: 20px; /* Space on the left side for bullet points */
  list-style-type: none; /* Bullet points */
  line-height: 1.6; /* Spacing between lines for readability */
}

/* Style for individual list items */
.action-points-content li {
  background-color: #fff; /* White background for list items */
  border: 1px solid #ddd; /* Light border around list items */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside list items */
  margin-bottom: 10px; /* Space between list items */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

/* Hover effect for list items */
.action-points-content li:hover {
  background-color: #f1f1f1; /* Light gray background on hover */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}
.summary {
  background-color: #ffffff; /* White background for a clean look */
  border: 1px solid #e0e0e0; /* Light gray border for subtle definition */
  border-radius: 8px; /* Rounded corners for a modern feel */
  padding: 20px; /* Adequate padding for spacing */
  margin: 20px 0; /* Spacing between sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.summary h3 {
  font-size: 24px; /* Larger font size for the title */
  color: #333333; /* Dark color for readability */
  border-bottom: 2px solid #e0e0e0; /* Underline for emphasis */
  padding-bottom: 10px; /* Padding below the title */
  margin-bottom: 20px; /* Margin below the title */
}

.summary .flex {
  display: flex; /* Flexbox for layout */
  justify-content: space-between; /* Space between title and language selector */
  align-items: center; /* Center items vertically */
}

.summary label {
  font-size: 16px; /* Font size for label */
  color: #333333; /* Consistent color for readability */
}

.summary select {
  font-size: 16px; /* Font size for select */
  padding: 8px 12px; /* Padding inside select box */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 4px; /* Slightly rounded corners */
  background-color: #ffffff; /* White background */
  cursor: pointer; /* Pointer cursor on hover */
}

.summary select:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.25); /* Focus shadow */
}

.summary p {
  font-size: 16px; /* Font size for the summary text */
  color: #333333; /* Dark color for readability */
  line-height: 1.6; /* Line height for better readability */
  margin-top: 0; /* Remove default top margin */
}
.sentiment h3 {
  font-size: 24px; /* Larger font size for the title */
  color: #333333; /* Dark color for readability */
  border-bottom: 2px solid #e0e0e0; /* Underline for emphasis */
  padding-bottom: 10px; /* Padding below the title */
  margin-bottom: 20px; /* Margin below the title */
}
.sentiment-container {
  display: flex;
  align-items: center;
  background-color: #f4f4f9; /* Light background */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.sentiment-container i {
  font-size: 2rem; /* Larger icon */
  margin-right: 10px;
  color: #333; /* Dark color for the icon */
}

.sentiment-text {
  font-family: "Arial", sans-serif;
  font-size: 1.2rem;
  color: #333;
}

.sentiment-container i.fa-smile {
  color: #4caf50; /* Green for positive */
}

.sentiment-container i.fa-sad-tear {
  color: #f44336; /* Red for negative */
}

.sentiment-container i.fa-meh {
  color: #ffc107; /* Amber for neutral */
}

.sentiment-container i.fa-question-circle {
  color: #9e9e9e; /* Grey for other */
}

/* Styles for the button and messages */
button.submit {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding around the text */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  display: flex; /* Flexbox for centering content */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

button.submit:disabled {
  background-color: #cccccc; /* Light gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor when disabled */
}

button.submitting {
  background-color: #0056b3;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Keyframes for the spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ml-40 {
  margin-left: -40px;
}
.sidebar {
  background: linear-gradient(180deg, #203b32 0%, #1d3a2f 48.5%, #203b32 97%);
}

.coming {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  flex-direction: column;
}
