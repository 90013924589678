/* body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #74ebd5, #acb6e5);
  color: #2d3a40;
} */

.container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

#log {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  height: 75vh;
  overflow-y: auto;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: all 0.3s ease;
}

.log-entry {
  display: flex;
  align-items: flex-end;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.log-entry:hover {
  transform: translateY(-3px);
  background-color: #f1f1f1;
  border-radius: 8px;
}

.customer {
  justify-content: flex-start;
}

.agent {
  justify-content: flex-end;
}

.message {
  max-width: 70%;
  padding: 15px 20px;
  border-radius: 20px;
  font-size: 18px;
  line-height: 1.6;
  position: relative;
  color: #fff;
}

.customer .message {
  background-color: #424242;
  border-bottom-left-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agent .message {
  background-color: #424242;
  border-bottom-right-radius: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-history .message {
  color: #85abe6; /* Text color for Customer [History] */
}

.timestamp {
  font-size: 12px;
  color: #555;
  margin-top: 8px;
  font-weight: 600;
  display: inline-block;
  padding: 4px 8px;
  background-color: #eee;
  border-radius: 12px;
  margin-left: 14px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.record-button1 {
  background-color: #ff4d4d; /* Default red color */
  border: none;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dbeaff !important;
}

.record-button1.active {
  background-color: #28a745; /* Green when active */
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.record-button1:hover {
  transform: scale(1.15);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.record-button1 svg {
  color: white; /* Default icon color */
  transition: color 0.3s ease;
}

.record-button1.active svg {
  color: #fff; /* Icon color when active */
}

.record-button1:hover {
  transform: scale(1.15);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.success-message {
  display: block; /* Ensure it is displayed */
  margin-top: 10px;
  padding: 12px;
  border-radius: 8px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.micText {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}
