.about-us {
  background-color: #f9f9f9;
  padding: 50px 20px;
}
/* About Us Section */
.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background: linear-gradient(135deg, #e0f7fa, #f6fffd);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.about-content {
  max-width: 50%;
}

.about-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 1.2em;
  line-height: 1.6;
}

.vision-section {
  text-align: center;
  padding: 50px;
  /* background: #f6fffd; */
  border-radius: 15px;
}

.vision-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.values-list {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.values-list li {
  font-size: 1.2em;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.mission-section {
  text-align: center;
  padding: 50px;
  background: #e0f7fa;
  border-radius: 15px;
  margin-top: 30px;
}

.mission-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.mission-icons {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 30px;
}

.icon-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.icon-item:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.testimonials-section {
  text-align: center;
  padding: 50px;
  background: #f1f1f1;
  border-radius: 15px;
  margin-top: 30px;
}

.testimonials {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.testimonial {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 30%;
}

.partners-section {
  text-align: center;
  padding: 50px;
  background: #e0f7fa;
  border-radius: 15px;
  margin-top: 30px;
}

.partners-logos img {
  max-width: 100px;
  margin: 0 20px;
  opacity: 0.8;
  transition: opacity 0.3s, transform 0.3s;
}

.partners-logos img:hover {
  opacity: 1;
  transform: scale(1.1);
}

.team-section {
  text-align: center;
  padding: 50px;
  background: #f6fffd;
  border-radius: 15px;
  margin-top: 30px;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member {
  text-align: center;
  margin: 20px;
}

.team-member img {
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.team-member img:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.header-content-aboutus p {
  width: 85% !important;
  font-size: 1.1em !important;
  display: flex;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 2000px; /* Adjust width as needed */
  height: 600px; /* Adjust height as needed */
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
}

.vision-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
}
