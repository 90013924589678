body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #005244;
  color: white;
}

.header-content {
  max-width: 50%;
}

.header-content h1 {
  font-size: 3.1em;
  margin: 0;
}
.header-content h2 {
  font-size: 2.5em;
  margin: 0;
}

.header-content p {
  font-size: 1.3em;
  margin-top: 10px;
  width: 50%;
}

.cta-button {
  padding: 15px 30px;
  background-color: #ffc107;
  color: #005244;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  margin-top: 20px;
}

.header-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.how-to-transcribe {
  padding: 50px;
  background-color: #f1f1f1;
  text-align: center;
}

.how-to-transcribe h2 {
  font-size: 2.8em;
  font-weight: 600;
}
.highlight {
  color: #01694e;
}
.how-to-transcribe .steps {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.how-to-transcribe .step {
  background-color: #f6fffd;
  padding: 20px;
  border-radius: 10px;
  width: 22%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefits {
  padding: 50px;
  background-color: #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.benefit {
  /* background: linear-gradient(115.45deg, #01694e 3.28%, #03805f 98.44%); */
  background-color: #f6fffd;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.benefit h3 {
  font-family: IBM Plex Sans;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}
.benefit p {
  font-size: 1em;
  width: 90%;
}

.faq {
  padding: 50px;
  text-align: center;
}

.faq h2 {
  font-size: 2em;
}

.faq-item {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.site-header {
  background-color: #005244; /* Dark green background */
  padding: 2.5vh 0;
  color: #fff;
  border-bottom: 2px solid #24987a;
  position: sticky;
  top: 0;
  z-index: 999;
}

.headerContainer {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation ul li {
  margin-right: 20px;
}

.navigation ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s, border 0.3s;
  border: 2px solid transparent;
  padding: 5px;
}

.navigation ul li a:hover,
.navigation ul li a.active {
  color: #e0e0e0;
  border-bottom: 2px solid #ffc107;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.login-button,
.start-button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.login-button {
  background-color: #ffd700; /* Yellow background */
  color: #365d4a;
  margin-right: 10px;
}

.start-button {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
}

.login-button:hover,
.start-button:hover {
  opacity: 0.8;
}
.login_button {
  width: 5vw;
  padding: 10px;
}

/* updated one */
.step-icons {
  width: 60px;
  height: 60px;
  border: 1px solid #ffc107;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #01694e;
  font-size: 1.5em;
  background-color: #fff;
}
.step h1 {
  font-family: IBM Plex Sans;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 49px;
  text-align: center;
}
/* languages */
.languages {
  padding: 60px 20px;
  text-align: center;
  background: #f9ffff;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.header-title {
  font-size: 2.8rem;
  color: #004d40;
  margin-bottom: 1.2rem;
  font-weight: bold;
}

.header-description {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 2.5rem;
  line-height: 1.7;
}

.languages_ct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.text-content {
  max-width: 50%;
  text-align: left;
  padding: 0 1.5rem;
}

.text-title {
  font-size: 2.2rem;
  color: #004d40;
  margin-bottom: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.text-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1.8rem;
  line-height: 1.7;
  display: flex;
  gap: 2rem;
}

.icon {
  font-size: 1.6rem;
  color: #00796b;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}
/* FAQ */
.faq {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 2vh;
}

.faq h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}
.faq p {
  font-size: 1.2rem;
}

.faq-item {
  margin-bottom: 10px;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.faq-header:hover {
  background-color: #f1f1f1;
}

.faq-header h3 {
  font-size: 1.25rem;
  color: #555;
  margin: 0;
}

.faq-header svg {
  font-size: 1.2rem;
  color: #004d40;
  transition: transform 0.3s ease;
}

.faq-header svg:hover {
  color: #004d40;
}

.faq-item p {
  margin: 10px 0 0;
  color: #666;
  line-height: 1.6;
  border-top: 1px solid #004d40;
  padding-top: 15px;
}

.faq-item h3 {
  margin: 0;
}

.faq-item:last-of-type {
  border-bottom: none;
}

/* footer */
.footer {
  background-color: #005244;
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.footer-logo {
  max-width: 100px;
  margin-right: 20px;
}

.footer-top h3,
.footer-top p {
  margin: 0;
  padding: 0;
}

.footer-top h3 {
  font-size: 24px; /* Increased font size */
  margin-right: 20px;
}

.footer-top p {
  font-size: 16px; /* Increased font size */
  margin-top: 20px;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left; /* Align text to the left */
}

.footer-links div {
  margin: 20px 0;
}

.footer-links h4 {
  margin-bottom: 10px;
  font-size: 18px; /* Increased font size */
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin: 5px 0;
}

.footer-links ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px; /* Increased font size */
  display: inline-block;
  padding: 5px 0;
  transition: border-bottom 0.3s;
}

.footer-links ul li a:hover {
  border-bottom: 2px solid yellow; /* Yellow border bottom on hover */
}

.footer-links ul li a.active {
  border-bottom: 2px solid yellow; /* Yellow border bottom for active link */
}

.footer-bottom {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 10px;
  font-size: 18px; /* Increased font size */
}
.footer-logo-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 280px;
  text-align: center !important;
}

.ax {
  font-size: 28px !important;
  margin: 3px;
  font-weight: 700;
}

.b-icons {
  margin-top: 4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* General Contact Us Container */
.contact-us {
  margin: 0 auto;
  padding: 40px 20px;

  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  z-index: 1;
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Header Section */
.contact-us-header {
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
  position: relative;
  z-index: 2;
}

.contact-us-header h1 {
  font-size: 48px;
  text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  animation: slideInDown 1s ease-out;
}

.contact-us-header p {
  font-size: 20px;
  animation: slideInUp 1s ease-out;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form and Map Layout */
.contact-us-form-map {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.contact-us-form {
  background: #2c7a7b;
  padding: 40px;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  animation: bounceIn 1.2s ease-out;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.contact-us-form:hover {
  transform: scale(1.05);
}

/* Form Fields */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  /* border: 1px solid rgba(255, 255, 255, 0.6); */
  border-radius: 8px;
  font-size: 16px;
  /* color: #004d40; */
  /* background-color: #b2f5ea; */
  backdrop-filter: blur(5px);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.headcolor {
  color: transparent !important;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #00bfa5;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 191, 165, 0.5);
}

/* Submit Button */
.sub-button {
  background-color: #00bfa5;
  color: #fff;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  animation: fadeInUp 1.5s ease-out;
}

.sub-button:hover {
  background-color: #00897b;
  transform: translateY(-5px);
}

/* Map Section */
.contact-us-map {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  animation: fadeInRight 1.5s ease-out;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 77, 64, 0.7);
  z-index: 2;
  transition: background 0.3s ease;
}

.contact-us-map:hover .map-overlay {
  background: rgba(0, 77, 64, 0.3);
}

@media (max-width: 768px) {
  .contact-us-form {
    padding: 30px;
  }

  .contact-us-map {
    height: 300px;
  }
}
/* pricing */

.pricing-table {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
}

.pricing-plan {
  background-color: #2c7a7b;
  color: white;
  border-radius: 15px;
  width: 250px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.pricing-plan.best-value {
  background-color: #ecc94b;
  color: black;
  transform: scale(1.05);
  z-index: 1;
}

.plan-header {
  background-color: #ffffff22;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.plan-header span {
  background-color: #ffffffaa;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: bold;
}

.price {
  font-size: 40px;
  margin: 10px 0;
  font-weight: bold;
}

.features {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  font-size: 14px;
}

.features li {
  margin: 10px 0;
  border-bottom: 1px dashed #ffffff88;
}

.plan-description {
  font-size: 12px;
  margin: 20px 0;
  color: #b2f5ea;
}

.choose-plan {
  background-color: #2f855a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.choose-plan:hover {
  background-color: #276749;
}
.pricing-plan {
  background-color: #2c7a7b;
  color: white;
  border-radius: 15px;
  width: 250px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pricing-plan.best-value {
  background-color: #ecc94b;
  color: black;
  transform: scale(1.05);
  z-index: 1;
}

/* Add hover effect to animate color change */
.pricing-plan:hover {
  background-color: #f6e05e; /* Or any color you prefer */
  color: #004d40;
  transform: scale(1.1); /* Slightly more scaling for hover effect */
}

.pl-img {
  padding-right: 8vw;
}
/* 404 */
/* src/components/PageNotFound.css */
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.not-found-content {
  max-width: 600px;
  padding: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.not-found-title {
  font-size: 6rem;
  color: #ff6347; /* Tomato color */
  margin-bottom: 20px;
}

.not-found-subtitle {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.not-found-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.not-found-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; /* Bootstrap primary blue color */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
